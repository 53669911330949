@use '../../styles/mixins.scss' as *;
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: px-to-rem(4px);
    width: px-to-rem(123px);
    padding: px-to-rem(9px) 0;

    box-sizing: border-box;

    background: #1B1B1B;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 25px;

            
    font-weight: 500;
    font-size: px-to-rem(17px);
    line-height: px-to-rem(20px);

    color: rgba(255, 255, 255, 0.5);

    transition: .3s;
    cursor: pointer;

    &--opened { gap: px-to-rem(8px); }

    &:hover { opacity: .8; }
}