@use '../../styles/mixins.scss' as *;

.new-profits-info {
    cursor: pointer;
    box-sizing: border-box;

    position: absolute;
    left: px-to-rem(15px);
    bottom: px-to-rem(27px);

    background: linear-gradient(180deg, #2B9FFF 0%, #0664B3 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: px-to-rem(500px);
    
    padding: px-to-rem(5px) px-to-rem(21px) px-to-rem(5px) px-to-rem(5px);

    display: flex;
    gap: px-to-rem(8px);
    align-items: center;
    
    &__circle {
        width: px-to-rem(34px);
        height: px-to-rem(34px);
        border-radius: 50%;
        background: #D1EAFF;

        display: flex;
        justify-content: center;
        padding-top: px-to-rem(8px);
    }

    &__text {
        font-weight: 600;
        font-size: px-to-rem(18px);
        line-height: px-to-rem(21px);

        color: #FFFFFF;
    }
}