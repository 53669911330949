@use '../../styles/mixins.scss' as *;

.textbox {
    margin-top: px-to-rem(9px);
    padding: px-to-rem(17px) px-to-rem(17px) px-to-rem(15px) px-to-rem(15px);

    display: flex;
    background: #1A1A1A;
    border-radius: 15px;

    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    &--small {
        background: #1B1B1B;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: px-to-rem(8px);
        padding: px-to-rem(9px);
    }

    &--reversed { 
        flex-direction: row-reverse; 
        gap: px-to-rem(7px);
    }

    &__input {
        background-color: transparent;
        border: none;

        width: 92%;
        font-weight: 500;
        font-size: px-to-rem(17px);
        line-height: px-to-rem(20px);

        color: rgba(255, 255, 255, 0.7);

        &::placeholder { color: rgba(255, 255, 255, 0.5); }

        &:focus {
            border: none;
            outline: none;
        }

        &--small { font-size: px-to-rem(15px); }
    }
}

.textbox-label {
    font-weight: 500;
    font-size: px-to-rem(17px);
    line-height: px-to-rem(20px);

    color: rgba(255, 255, 255, 0.5);
}