// Импорт миксинов
@import './mixins';

// Базовые глобальные стили
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: 'SF Pro Display', sans-serif;
  background-color: #1e1e20;
  font-size: 4vw;

  @media (min-width: 800px) {
    font-size: 16px;
  }
}

.container {
  padding: px-to-rem(15px);
  width: px-to-rem(440px);
  box-sizing: border-box;
}
