@use '../../styles/mixins.scss' as *;

.header {
    &__top {
        display: flex;
        justify-content: space-between;
        padding: 0 px-to-rem(15px);
    }

    &__name {
        font-weight: 600;
        font-size: px-to-rem(24px);
        line-height: px-to-rem(29px);
        text-align: center;

        color: #FFFFFF;
    }

    &__logo {
        cursor: pointer;
        width: px-to-rem(26px);
        height: px-to-rem(24px);
    }

    &__info {
        display: flex;
        align-items: center;
        gap: px-to-rem(15px);
    }

    &__search {
        margin-top: px-to-rem(30px);
    }

    &__result-title {
        font-weight: 600;
        font-size: px-to-rem(24px);
        line-height: px-to-rem(29px);

        color: #FFFFFF;
        margin-left: px-to-rem(13px);
        margin-top: px-to-rem(20px);
    }
}