@use '../../styles/mixins.scss' as *;

.profit-item {
    background: #1B1B1B;
    border-radius: px-to-rem(20px);
    position: relative;
    overflow: hidden;

    &--red {
        &::before {
            content: "";
            position: absolute;
    
            width: 46.0975609756098%;
            height: 52.2613065326633%;
    
            left: 50%;
            bottom: -50%;
            transform:translate(-50%, -50%);
            background: linear-gradient(180deg, rgba(255, 0, 0, 0.3) 0%, rgba(255, 0, 0, 0.3) 100%);
            filter: blur(75px);
        }
    
        &::after {
            content: "";
            position: absolute;
            
            width: 96.8292682926829%;
            height: 104.0201005025126%;
    
            left: 50%;
            top: 9.5477386934673%;
    
            transform:translate(-50%);
    
            background: linear-gradient(180deg, rgba(255, 38, 0, 0.2) 0%, rgba(255, 128, 0, 0.2) 100%);
            filter: blur(75px);
        }
    }


    &__small-effect {
        content: "";
        position: absolute;

        width: 46.0975609756098%;
        height: 52.2613065326633%;

        background: linear-gradient(180deg, rgba(255, 0, 0, 0.3) 0%, rgba(255, 0, 0, 0.3) 100%);
        filter: blur(75px);
    }

    &__header {
        background: #242424;
        border-radius: px-to-rem(20px) px-to-rem(20px) 0px 0px;
        display: flex;
        justify-content: space-between;
        padding: px-to-rem(16px) px-to-rem(23px);
    }

    &__success {
        font-weight: 500;
        font-size: px-to-rem(18px);
        line-height: px-to-rem(21px);

        color: #FFFFFF;

    }

    &__date {
        font-weight: 500;
        font-size: px-to-rem(17px);
        line-height: px-to-rem(20px);
        
        color: rgba(255, 255, 255, 0.5);
    }

    &__main {
        padding: px-to-rem(18px) px-to-rem(24px) px-to-rem(25px) px-to-rem(23px);
    }

    &__deal-info {
        display: flex;
        justify-content: space-between;
    }

    &__profit-value {
        font-weight: 700;
        font-size: px-to-rem(22px);
        line-height: px-to-rem(26px);
       
        &--blue { color: #2B9FFF; }
        &--red { color: #FF2600; }
        &--gray { color: #A1B0BC; }
        &--green { color: #3CCF1E; }

        &--v {
            &::after {
                content: " ₽";
                opacity: .5;
                position: initial;
            }
        }
    }

    &__profit-from {
        width: px-to-rem(118px);
        padding: px-to-rem(5px) 0 px-to-rem(4px) 0;
        text-align: center;
        background-color: #fff;

        border-radius: px-to-rem(7px);

        font-weight: 600;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(19px);

        color: #1B1B1B;
    }

    &__user-data {
        margin-top: px-to-rem(14px);
        display: flex;
        gap: px-to-rem(7px);
        align-items: center;
    }

    &__username {
        font-style: normal;
        font-weight: 500;
        font-size: px-to-rem(17px);
        line-height: px-to-rem(20px);

        color: rgba(255, 255, 255, 0.5);
    }

    &__transaction-type {
        margin-top: px-to-rem(15px);
    
        background: #2B2B2B;
        border-radius: px-to-rem(7px);
        padding: px-to-rem(5px) 0 px-to-rem(4px) 0;
        text-align: center;

        font-style: normal;
        font-weight: 600;
        font-size: px-to-rem(16px);
        line-height: px-to-rem(19px);

        color: rgba(255, 255, 255, 0.5);
    }
}