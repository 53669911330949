.home {
    display: flex;
    justify-content: center;

    height: 100vh;
}

.container {
    background-color: #f1eded;
    padding: 2rem;
    border-radius: 20px;
}