@use "../../styles/mixins.scss" as *;

.search {
  border-radius: px-to-rem(15px);

  &__toggle {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    background: #444;
  }
}

.search-content {
  border-radius: px-to-rem(15px);
  margin-top: 1rem;

  &--active {
    display: block;
  }

  &--regular {
    display: flex;
    gap: 0.5rem;

    &__input {
      flex: 1;
      padding: 0.5rem;
      border: 1px solid #555;
      border-radius: 8px;
      background: #222;
      color: #fff;
    }

    &__button {
      background: #555;
      border: none;
      color: #fff;
      cursor: pointer;
      padding: 0.5rem;
      border-radius: 8px;
    }
  }

  &--advanced {
    background-color: #1a1a1a;
    padding: px-to-rem(12px) px-to-rem(15px);
    .input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;

      &__input {
        padding: 0.5rem;
        border: 1px solid #555;
        border-radius: 8px;
        background: #222;
        color: #fff;
      }
    }

    .checkbox-group {
      margin-top: px-to-rem(15px);

      display: flex;
      flex-wrap: wrap;

      &--service {
        margin-top: px-to-rem(20px);
      }
    }
  }
}
.search-toggle {
  border-radius: px-to-rem(15px);

  &__button {
    box-sizing: border-box;

    white-space: nowrap;
    width: 50%;
    padding: px-to-rem(11px);
    background: #1b1b1b;

    font-weight: 500;
    font-size: px-to-rem(17px);
    line-height: px-to-rem(20px);

    color: #1b1b1b;

    color: rgba(255, 255, 255, 0.25);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;

    &:first-child {
      border-top-left-radius: px-to-rem(15px);
      border-bottom-left-radius: px-to-rem(15px);

      border-width: 1px 0px 1px 1px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.1);
      border-radius: x-to-rem(15px) 0px 0px x-to-rem(15px);
    }

    &:last-child {
      border-top-right-radius: px-to-rem(15px);
      border-bottom-right-radius: px-to-rem(15px);

      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 0px px-to-rem(15px) px-to-rem(15px) 0px;
    }

    &--active {
      background-color: #242424;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.inputs-group {
  display: flex;
  flex-wrap: wrap;
  gap: px-to-rem(15px) px-to-rem(21px);
}

.checkbox-items {
  margin-top: px-to-rem(9px);
  display: flex;
  flex-wrap: wrap;
  gap: px-to-rem(15px) px-to-rem(15px);
}
