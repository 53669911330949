@use '../../styles/mixins.scss' as *;

.label { margin: 0; }

.checkbox {
    display: flex;
    gap: px-to-rem(6px);
    cursor: pointer;
    font-size: 1rem;
    color: #ccc;
    user-select: none;

    &__input {
        display: none; // Скрываем стандартный чекбокс
    }

    &__box {
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: px-to-rem(6px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1B1B1B;
        transition: background-color 0.3s, border-color 0.3s;
    }

    &__input:checked + &__box {
        background-color: #fff;
        border: 3px solid #fff;
    }

    &__input:checked + &__box::after {
        content: '';
        width: 0.6rem;
        height: 0.6rem;
        background-color: #222;
        border-radius: 2px;
    }

    &__label {
        font-weight: 500;
        font-size: px-to-rem(17px);
        line-height: px-to-rem(20px);

        color: rgba(255, 255, 255, 0.5);

        &--checked { color: #fff; }
    }
}
