@use '../../styles/mixins.scss' as *;

.wrapper {
    margin-top: px-to-rem(30px);

    position: relative;
}

.list {
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    
    &__items {
        display: flex;
        flex-direction: column-reverse;
    }
}